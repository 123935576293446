import { graphql, useStaticQuery } from "gatsby";

import React from "react";
import Shuffler from "../../../components/Shuffler";

export const Tagline = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        tagline
        typelines
      }
    }
  `);

  return (
    <div className="my-6 text-center text-sm text-white opacity-90 md:text-right">
      <Shuffler
        tagline={data.contentfulSite.tagline}
        typelines={data.contentfulSite.typelines}
      />
    </div>
  );
};
