import React from "react";
import bg from "../assets/images/background.jpg";

const LayoutBackground = () => {
  return (
    <div
      style={{ backgroundImage: `url(${bg})` }}
      className={`h-full w-full shadow-inner bg-no-repeat bg-contain sm:bg-cover bg-[top_-1rem_center] sm:bg-[top_-28rem_center] md:bg-[bottom_center]`}
    />
  );
};

export default LayoutBackground;
