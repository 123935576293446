import { graphql, useStaticQuery } from "gatsby";

import React from "react";

export const Footer = () => {
  const year = React.useMemo(() => new Date().getFullYear(), []);

  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        siteTitle
      }
    }
  `);

  return (
    <div className="mx-4 border-t sm:px-2 md:px-6 lg:px-12 text-stone-400">
      {/* <p className="pt-12 pb-4">{`Taproot is a tax-exempt 501(C)(3) nonprofit organization (tax ID #82-1454431)`}</p> */}
      <p className="pt-12 pb-8">
        {data.contentfulSite.siteTitle}
        <br />
        20 First St. Unit B
        <br />
        Rochester, NY 14605
      </p>
    </div>
  );
};
