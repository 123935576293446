module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/node_modules/@gregorshear/gatsby-theme-taproot/src/intl","languages":["en-US"],"defaultLanguage":"en-US"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/node_modules/@gregorshear/gatsby-theme-taproot/src/layouts/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"taprootcollective.org","short_name":"Taproot","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"browser","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a0e3e14bd5784fcb51cee9e44eb4c778"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"e4a94fdb2dce92fd0263c3fb0059b552","pageViews":"all","trackPageViewsAs":"page view"},
    },{
      plugin: require('../node_modules/@gregorshear/gatsby-theme-taproot/gatsby-browser.js'),
      options: {"plugins":[],"contentful":{"spaceId":"jl8vfxa0cm5w","accessToken":"7JgvKcS-fteJiYuP9iRYZxuXaPE1_t0p_16EsFfBkVY"},"algolia":{"appId":"UGPE5T7VHJ","apiKey":"2d5548ef4bc8cc02dc7e9cbab4ac17b9"},"manifest":{"name":"taprootcollective.org","short_name":"Taproot"},"mixpanel":{"apiToken":"e4a94fdb2dce92fd0263c3fb0059b552","pageViews":"all","trackPageViewsAs":"page view"},"tailwindTheme":{"extend":{"colors":{"primary":{"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"},"secondary":{"50":"#f7fee7","100":"#ecfccb","200":"#d9f99d","300":"#bef264","400":"#a3e635","500":"#84cc16","600":"#65a30d","700":"#4d7c0f","800":"#3f6212","900":"#365314","950":"#1a2e05"}}}}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Urbanist","file":"https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
