import { FiHeart as HeartIcon } from "react-icons/fi";
import React from "react";

const CTAButton = ({ className = "" }: { className: string }): JSX.Element => (
  <a
    href="https://secure.givelively.org/donate/taproot-collective-inc"
    // href="https://roctheday.org/taprootcollective" // for giving tuesday
    target="_blank"
    rel="noreferrer"
    aria-label="Link to donation page"
  >
    <p
      className={`flex justify-center items-center font-normal rounded-lg py-1 px-3 my-1 text-md text-slate-600 bg-primary-300 hover:bg-primary-400 hover:text-black ${className}`}
    >
      {`Donate`}
      <HeartIcon className="ml-2 mb-1 h-4 w-4 inline text-slate-600" />
    </p>
    {/* <img src={bg} height={40} /> */}
  </a>
);

export default CTAButton;
