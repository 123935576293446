import React from "react";
import { ReactTyped } from "react-typed";
import reactStringReplace from "react-string-replace";

export const Shuffler = ({
  tagline,
  typelines = [],
  className,
}: {
  tagline?: string;
  typelines?: string[];
  className?: string;
}) => (
  <h5 className={className}>
    {tagline}
    {typelines.length > 0 && (
      <>
        <br />
        {reactStringReplace("%TOPICS%", "%TOPICS%", (match, i) => (
          <ReactTyped
            strings={typelines}
            typeSpeed={40}
            backSpeed={60}
            shuffle={true}
            backDelay={2000}
            loop={true}
            key={match + i}
          />
        ))}
      </>
    )}
  </h5>
);

export default Shuffler;
