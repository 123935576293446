import React from "react";
import logo from "../assets/images/logo.svg";
import { navigate } from "gatsby-plugin-intl";

export const SiteLogo = () => (
  <img
    onClick={() => navigate("/")}
    src={logo}
    alt="Site logo"
    className="mb-4 w-64 cursor-pointer"
  />
);
